import React from "react";
import {
  CardGrid,
  CardGridProps,
} from "@ecologi/react-components/src/CardGrid";
import { useRouter } from "next/router";

type Props = {
  data: CardGridProps;
};

export default function CardGridWidget({ data }: Props) {
  const router = useRouter();
  return <CardGrid {...data} slug={router?.query?.slug} />;
}
