import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Section,
  SectionHeadingProps,
  BackgroundVariantProps,
  BackgroundVariant,
  backgroundMapper,
} from "../Section";
import { Card, BaseCardProps } from "../Card";
import { Typography } from "../Typography";
import { isImpactShop } from "../utils/is-impact-shop";
import { desktopUp, mobileDown } from "../theme/ecologi-breakpoints";
import * as bp from "@ecologi/react-components/src/theme/ecologi-breakpoints";

export type CardGridProps = Omit<SectionHeadingProps, "title" | "icon"> & {
  cards: BaseCardProps[];
  title?: string;
  footer?: string;
  isCompact?: boolean;
  slug?: string | string[];
  text_color: string;
  card_id?: string;
} & BackgroundVariantProps;

const MW_380 = `only screen and (max-width: 380px)`;

const gridVariants = {
  zero_first: css`
    margin-bottom: 50px;
    @media ${MW_380} {
      grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    }
  `,
  zero_third: css`
    @media ${MW_380} {
      grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    }
  `,
};

const Grid = styled.div<{ slug: string }>`
  display: grid;
  grid-gap: 34px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

  @media ${(props) => props.theme.bp.tabletUp} {
    grid-gap: 24px;
    width: 100%;
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
  ${(props) => gridVariants[props.slug]};
`;

const Subtitle = styled(Typography)<{ color: string; slug: string }>`
  text-align: center;
  ${(props) =>
    props.color === "CHARCOAL"
      ? css``
      : css`
          color: ${backgroundMapper[props.color](props.theme)};
        `};
  ${(props) => subtitleVariants[props.slug]}
`;

const TitleWrapper = styled(Section.Title)<{
  isImpactPage?: boolean;
  color: string;
}>`
  ${(props) =>
    props?.isImpactPage
      ? css`
          font-size: 40px !important;
          @media ${mobileDown} {
            font-size: 26px !important;
          }
          @media ${desktopUp} {
            width: 800px;
          }
        `
      : css``};

  ${(props) =>
    props.color === "CHARCOAL"
      ? css``
      : css`
          color: ${backgroundMapper[props.color](props.theme)};
        `};
`;

const subtitleVariants = {
  zero_third: css`
    font-size: 18px !important;
    max-width: 820px;
    ${bp.mobileDown} {
      font-size: 14px !important;
    }
  `,
};

const SubtitleWrapper = styled(Section.Subtitle)<{
  isImpactPage?: boolean;
  slug: string;
}>`
  font-size: ${(props) => (props?.isImpactPage ? "18px !important" : "")};
`;

export function CardGrid({
  cards,
  backgroundVariant,
  slug,
  ...props
}: CardGridProps) {
  const sectionProps =
    cards.length > 3 ? { isNewExtraLarge: true } : { isLarge: true };
  const newSlug = slug + (props?.card_id ? "_" + props.card_id : "");
  const headingProps = newSlug.includes("third") ? { isLarge: true } : {};

  return (
    <Section
      {...props}
      slug={props?.card_id ? newSlug : slug}
      {...sectionProps}
      backgroundVariant={backgroundVariant}
      isExtraLarge={isImpactShop(slug)}
    >
      <>
        <Section.HeadingWrapper {...headingProps}>
          {props.title && (
            <TitleWrapper
              use="featureTitle"
              as="h1"
              isImpactPage={isImpactShop(slug)}
              color={props.text_color}
            >
              {props.title}
            </TitleWrapper>
          )}
          {props.isCompact ? (
            <SubtitleWrapper
              use="subtitle2"
              as="h2"
              slug={newSlug}
              isImpactPage={isImpactShop(slug)}
            >
              {props.subtitle}
            </SubtitleWrapper>
          ) : (
            <Subtitle
              use="subtitle2"
              as="h2"
              color={props.text_color}
              slug={newSlug}
            >
              {props.subtitle}
            </Subtitle>
          )}
        </Section.HeadingWrapper>

        <Grid slug={newSlug}>
          {cards.map((card, i) => (
            <Card
              variant={slug === "zero" ? "zero" : "compact"}
              key={`card-grid-item-${i}`}
              backgroundVariant={BackgroundVariant.TRANSPARENT}
              isImpactPage={isImpactShop(slug)}
              slug={newSlug}
              {...card}
            />
          ))}
        </Grid>
        {props.isCompact && (
          <>
            <br />
            <Section.HeadingWrapper>
              {props.footer && (
                <Section.Subtitle use="subtitle2" as="h2">
                  {props.footer}
                </Section.Subtitle>
              )}
            </Section.HeadingWrapper>
          </>
        )}
      </>
    </Section>
  );
}
